<script setup lang="ts">
const route = useRoute()
const redirect = useState('redirect')
const { $httpCookies } = useNuxtApp()
const { isSmallScreen, isSmallAndMediumScreen } = useBreakpoint()
const { headerHeight } = useHeaderNavigation()
const { user } = useUser()

const isTgv = ref(redirect.value === 'tgv')
const isBonderco = ref(redirect.value === 'bonderco')
const isVallat = ref(redirect.value === 'vallat')
// Init header
const cssVar = computed(() => {
  return {
    '--header-height': `${headerHeight.value}px`,
  }
})
const name = computed(() => (route.name as string)?.split('___')?.[0])
const disableAffix = computed(() => ['search'].includes(name.value))

const closeModal = () => {
  // Clear cookie
  $httpCookies.set('x-source-page', '', {
    domain: '.lecollectionist.com',
  })

  // Clear state
  isTgv.value = false
  isBonderco.value = false
  isVallat.value = false
  redirect.value = ''
}

defineSlots<{ default(): any }>()
</script>

<template>
  <div class="default-layout" :style="cssVar">
    <TheHeader :disable-affix="disableAffix" />
    <TheMenuMobile v-if="isSmallAndMediumScreen" />

    <div class="default-layout__content">
      <slot />
    </div>
    <TheFooter />
    <ThePopinNewsletter v-if="!user.newsletterSubscribed" />
    <ThePopinTgv :is-tgv="isTgv" @close-modal="closeModal" />
    <ThePopinBonder :is-bonder="isBonderco" @close-modal="closeModal" />
    <ThePopinVallat :is-vallat="isVallat" @close-modal="closeModal" />

    <WishlistMultipleSummaryWrapperMobile v-if="isSmallScreen" />
  </div>
</template>

<style>
.default-layout {
  @apply min-h-screen flex flex-col;
}
</style>
